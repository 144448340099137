
import ClientsOfficeView from '../../viewports/ClientsOfficeView'
import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import { Button, Breadcrumbs, Link, Typography, Grid } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useHistory } from 'react-router';
import { Link as RouteLink, useParams } from "react-router-dom";
import AssigListResults from './../../components/AssigListResults'
import SelectTestAndCreateAssignmentBtn from '../../components/SelectTestAndCreateAssignmentBtn';
import AssigListActive from '../../components/AssigListActive';
import moment from 'moment';
import CommonList from '../../components/CommonList';


export function personDeactivation(person) {
    if ( person.active ) {
        let ready = window.confirm("Вы уверены, что хотите заблокировать пользователя \""+ person.description +"\" (№"+person.customNumber+")? Это нельзя отменить.");
        if ( ready ) {
            console.log('onBlock', person.id)
            return api.get_org_person_deactivation(person.id)
        }
    } 
    return Promise.reject(0)
}
  
export default function TestListPage(props) {
    const [person, setPerson] = useState([])
    const [indexUpdate, setIndeUpdate] = useState(0)
    const history = useHistory()
    let { id } = useParams();

    useEffect(() => {
        api.get_org_person_detail(id).then(
            res => setPerson(res),
            err => {}
        )
    },[indexUpdate, history, id])

    return (
        <ClientsOfficeView>
            <Breadcrumbs aria-label="breadcrumb">
                <Link component={RouteLink} color="inherit" to="/dashboard" >
                    Главная
                </Link>
                <Link component={RouteLink} color="inherit" to="/personlist" >
                    Участники
                </Link>
                <Typography color="textPrimary">{person.description}</Typography>
            </Breadcrumbs>

            <Grid container alignItems="center" spacing={3} style={{marginTop:'20px'}}>
                <Grid item xs={4}>
                    <h1 style={{margin:0}}>
                        {person.description}
                    </h1>
                </Grid>
                <Grid item xs>
                    <small style={{color:'grey'}}>Таб.№ </small> <br/>
                    {person.customNumber} 
                </Grid>
                <Grid item xs>
                    <small style={{color:'grey'}}>Login</small> <br/>
                    {person.login}
                </Grid>
                <Grid item xs>
                    <small style={{color:'grey'}}>Password</small> <br/>
                    {person.password}
                </Grid>
                <Grid item xs style={{textAlign:'right', whiteSpace:'nowrap'}}>

                    <SelectTestAndCreateAssignmentBtn 
                        disabled={!person.active} 
                        ids={[person.id]} 
                        onChange={()=>setIndeUpdate(indexUpdate + 1)}
                    >
                        Назначить тест
                    </SelectTestAndCreateAssignmentBtn>

                    <Button 
                        disabled={!person.active} 
                        variant="contained" 
                        color="primary" 
                        onClick={() => {
                            personDeactivation(person);
                            setIndeUpdate(indexUpdate + 1)
                        }}
                    >
                        Заблокировать
                    </Button>

                    &nbsp;
                    &nbsp;
                </Grid>
            </Grid>
            

            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    
                    <h3>Назначенные тесты</h3>            

                    <AssigListActive
                        onSelect={x => history.push('/testlist/'+x.test.id)} 
                        icon={x => <AssignmentIcon />}
                        title={x => x.testDescription}
                        dataLoader={() => api.get_org_person_assignments_active(id)} 
                        indexUpdate={indexUpdate} 
                    />
                    
                </Grid>
                <Grid item xs={12} md={6}>

                    <h3>Пройденные тесты и доступные отчеты</h3>
                    <AssigListResults 
                        icon={x => <AssignmentIcon />}
                        title={x => x.testDescription}
                        onSelect={x => history.push('/testlist/'+x.test.id)} 
                        dataLoader={() => api.get_org_person_assignments_results(id)} 
                    />

                    <br/>
                    <h3>Не пройденные тесты</h3>

                    <CommonList 
                        onSelect={x => history.push('/testlist/'+x.test.id)} 
                        dataLoader={() => api.get_org_person_assignments_expired(id)} 
                        icon={x => <AssignmentIcon />}
                        title={x => x.testDescription}
                        desc={x => moment(x.expiresMoscow, 'DD.MM.YYYY HH:mm:ss').format('D MMM YYYY, HH:mm')} 
                        indexUpdate={indexUpdate} 
                    />

                </Grid>
            </Grid>

        </ClientsOfficeView>
    )
}


