
export default function TestView({onMouseDown = ()=>{}, children = '', style_mode_with_text = false}) {
    let style = {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        alignContent:'center',
        height:'100%',

    }
    if (style_mode_with_text) {
        style = {
            ...style,
            textAlign:'left',
            fontSize:'2.7vw',
            lineHeight:1.4,
            padding:'0px',
            userSelect: 'none'
        }
    }
    return (
        <div className="test-view" 
            onMouseDown={(e) => onMouseDown(e)} 
            style={{
                height:'100%', 
                padding: '50px', 
                background:'black', 
                color:'white', 
                fontSize:'25px', 
                textAlign:'center', 
                boxSizing: 'border-box'
            }}
        >
            <div style={style}>
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}