

// export let isDebug

function Debug({children, json}) {
    if (window.CONFIG.debug_mode) {
        return (
            <div>
                { json ? <pre>{JSON.stringify(json, true, "    ")}</pre> : '' }
                {children}
            </div>
        )
    } else {
        return ''
    }
}

Debug.is = window.CONFIG.debug_mode


export default Debug