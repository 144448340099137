
import TestView from './../../../viewports/TestView'
import { useHistory } from 'react-router';


export default function EndPageContent() {
    const history = useHistory();
    return (
        <TestView onMouseDown={() => history.push("/desk")}>
            <img src="/champion.png" alt="Спасибо" width="200" />
            <h3>Тест окончен</h3>
            <p>Спасибо за прохождение тестирования!</p>
            <p>Нажмите любую клавишу мыши.</p>
        </TestView>
    )
}
