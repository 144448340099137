
import Footer from './../components/Footer'
import './CoverView.css'


function CoverView({children, cover = 'cover_bg.jpg'}) {
    return (
        <div className="cover-wrapper" style={{backgroundImage: 'url('+cover+')'}}>
            <img className="psy-logo" src="logo_psyscanner_white.svg" width="200" alt="PSY Scanner" />
            <div className="cover-view">
                {children}
            </div>
            <Footer/>
        </div>
    )
}

export default CoverView