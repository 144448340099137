
import TestView from './../../../viewports/TestView'


export default function StartPageContent({onStart}) {
    return (
        <TestView onMouseDown={() => onStart()} style_mode_with_text={true}>
            <p>
                Будьте внимательны!
            </p>
            <p>
                На экране будут быстро мелькать цифры. <br/>
                При появлении цифр как можно быстрее нажимайте<br/>
                на кнопку компьютерной мыши и решайте, <br/>
                нажимать или не нажимать, если мелькнет слово.<br/>
                Чем меньше ошибок вы допускаете, тем лучше.
            </p>
            <p>
                Если готовы, нажмите кнопку мыши, <br/>
                работа начнется.
            </p>

            <audio src="/sounds/begin_pic.wav" autoplay=""></audio>
        </TestView>
    )
}
