
import CoverView from '../viewports/CoverView'


export default function Err404Page() {
    return (
        <CoverView cover="">
            <h1>Ой, 404 :-(</h1>
            <p>Страница не найдена</p>
        </CoverView>
    )
}