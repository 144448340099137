
import { Breadcrumbs, Button, Card, CardContent, TextField, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import api from '../../api/api'
import ClientsOfficeView from '../../viewports/ClientsOfficeView'
import { useSnackbar } from 'notistack';
import { Link } from '@material-ui/core';
import { Link as RouteLink } from "react-router-dom";

function DashboardPage(props) {
    const history = useHistory()
    const [name, setName] = useState('')
    const [logoUrl, setLogoUrl] = useState('/logo.png')
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        api.get_org_info().then(
            res => setName(res.name),
            err => {}
        )    
        api.get_org_logo().then(
            res => setLogoUrl(res.logoUrl),
            err => {}
        )
    }, [history])

    const setOrgName = () => {
        api.set_org_info(name).then(() => {
            enqueueSnackbar('Имя компании обновлено', { variant: 'success' })
        }, err => {});
    }

    const openDialog = (input_id) => {
        document.querySelector(input_id).click()
    }

    const img_blob_to_url = (blob) => {
        let urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL( new Blob([blob], {type: blob.type}) )
    }

    const changeLogo = (input) => {
        if (!input.files || input.files.length === 0) return
        setLogoUrl( img_blob_to_url( input.files[0] ) )
        api.set_org_logo( input.files[0] ).then(() => {
            enqueueSnackbar('Лого компании обновлено', { variant: 'success' })
        }, err => {});
    }

    return (
        <ClientsOfficeView>

            <Breadcrumbs aria-label="breadcrumb">
                <Link component={RouteLink} color="inherit" to="/dashboard" >
                    Главная
                </Link>
                <Typography color="textPrimary">Настройки</Typography>
            </Breadcrumbs>

            <h1>Настройки сервиса</h1>
           
            <h4>Брендирование</h4>

            <Card style={{height: 'auto', padding: '20px 100px'}}>
                <CardContent>
                    <Typography color="textSecondary">
                        Логотип и имя компании
                    </Typography>

                    <br />
                    
                    <img src={logoUrl} onClick={()=>{ openDialog('#logoInput') }} alt="logo" width="150" /> 
                    <input id="logoInput" type="file" style={{display:'none'}} onChange={(e) => changeLogo(e.target)} />

                    <br />
                    <br />

                    <TextField label="Имя компании" size="medium" variant="outlined" style={{width:'100%'}}
                        value={name} onChange={(e) => setName(e.target.value)} />
                        
                    <p>
                        <small>
                            Пользователи увидят данные настройки при авторизации
                        </small>
                    </p>

                    <Button variant="contained" color="primary" onClick={()=>setOrgName()}>Сохранить</Button>
                    
                </CardContent>
            </Card>

        </ClientsOfficeView>
    )
}

export default DashboardPage