
import BaseView from './../../../viewports/BaseView'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router';


export default function StopPageContent({onReset}) {
    const history = useHistory();
    return (
        <BaseView>
            <h1>Стоп</h1>

            <p>Тест остановлен.</p>
            <p>Придется начать с начала.</p>

            <Button onClick={() => onReset()} variant="contained" color="primary" >Начать с начала</Button>
            &nbsp;
            <Button onClick={() => history.push("/desk")} variant="outlined" >Назад на главную</Button>
        </BaseView>
    )
}
