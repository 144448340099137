
import { useEffect, useState } from 'react'
import TestView from './../../../viewports/TestView'
import FPS from './../../../components/FPS'
import Debug from '../../../components/Debug'


export default function CompCheckPageContent({onGood = ()=>{}, onFail = ()=>{} }) {
    const [status, setStatus] = useState(0)
    const [failCount, setFailCount] = useState(0)
    const [fail, setFail] = useState(false)
    useEffect(()=> {
        let iid = setInterval(()=> {
            setStatus(status + 1)

            if (Debug.is && status > 50) {
                onGood()
            }

            // небольшая тестовая нагрузка
            let x = 0
            for( let i=0; i < 100000; i++) {
                x = Math.log( Math.sqrt(i) * Math.sin(i) )
            }
            // небольшая тестовая нагрузка


            if (status > 1000) {
                finish(x)
            }
        }, 10)
        return () => {
            clearInterval(iid)
        }
    })
    const failCounter = (val) => {
        setFailCount( val )
        return ''
    }
    const finish =() => {
        if (failCount < 40 ) {
            onGood()
        } else {
            setFail(true)
        }
    }
    if (fail) {
        return (
            <TestView onMouseDown={() => onFail()} style_mode_with_text={true}>
                <p>Проверка компьютера</p>
                <p style={{color:'red'}}>
                    К сожалению, ваш комьютер не соответствует требованиям, <br/>
                    попробуте пройти тест с другого комьютера <br/>
                </p>
            </TestView>
        )
    }
    return (
        <TestView style_mode_with_text={true}>
            <p>Проверка компьютера</p>
            <p>
                Идет тестировние компьтера.... <br/>
            </p>
            <p style={{fontSize:'2vw'}}>
                тест под нагрузкой — {status > 500 ? 'ok' : status } <br/>
                {status >500 ? 'проверка пропуска кадров — ' + (status > 1000 ? 'ok' : status-500) : ''}<br/>
            </p>
            <br/>

            <FPS onFailCount={failCounter}/> 
        </TestView>
    )
}