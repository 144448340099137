
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CompCheckPageContent from './TestPageContent/CompCheckPageContent'
import StartPageContent from './TestPageContent/StartPageContent'
import TestPageContent from './TestPageContent/TestPageContent'
import StopPageContent from './TestPageContent/StopPageContent'
import EndPageContent from './TestPageContent/EndPageContent'
import api from '../../api/api';
import Debug from '../../components/Debug';
import { useSnackbar } from 'notistack';


function openFullScreen() {
    if (Debug.is) {
        return
    }
    if (document && !document.fullscreenElement && document.documentElement && document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
    } 
}

function closeFullScreen() {
    if (Debug.is) {
        return
    }
    if (document.fullscreenElement) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }
}

export default function TestPage(props) {
    const [test, setTest] = useState({})
    const [testStartTime, setTestStartTime] = useState(null)
    const [status, setStatus] = useState('check')
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();

    useEffect(() => {
        api.get_person_test_starting(id).then(
            testdata => setTest( testdata ),
            err => {}
        )
    }, [id])

    const sendResult = (report, testTimeMS) => {

        const reportItemToEventType = (repItem) => {
            switch (repItem.res) {
                case 'ERR_NEED':      return 4;
                case 'ERR_IN_VAIN':   return 3;
                case 'ERR_TO_LATE':   return 2;
                case 'ERR_TO_EARLY':  return 1;
                case 'right_click':   return 0;
                case 'right_wait':    return 0;
                default:              return 0;
            }
        }

        let res = {
            checkSum:               test.PsyHeader.CheckSum.toString(),     //*	string	
            passingTimeInSeconds:   Math.round(testTimeMS / 1000),          //*	integer($int32)	
            clusterResults:         test.Clusters.map((c,ci) => ({ 
                // ClusterResultModel
                groupName:              c.Header.GroupName,     //*	string	
                stimulusCount:          c.Stimulus.filter(s => s.Variant === 1 ).length,
                clusterType:            c.Header.ClusterType,   //*	integer($int32)	
                name:                   c.Header.Name,          //*	string	
                stimulusResults:        report
                    .filter(r => r.ClusterIndex === ci)
                    .map(r => ({
                        eventType: reportItemToEventType(r),
                        okResult: { 
                            //	StimulusOkResultModel
                            showDelayMs: r.before_delay_ms, //*	integer($int32)	
                            reactionDelayMs: r.time,        //*	integer($int32)	
                            stimulus: r.Stimulus,           //*	string	
                        }
                    }))
            }))
        }
        console.log('report', report)
        console.log('res', res)
        api.set_person_test_completion(id, res).then(
            r => {
                enqueueSnackbar('Данные отправлены', { 
                    variant: 'success',
                })
                console.log(r)
            },
            er => {
                enqueueSnackbar('Отправить результаты не удалось', { 
                    variant: 'error',
                })
                console.log(er, er.message, er.errors, er.title)
            }
        )
        
    }


    const onGood = () => {
        setStatus('start');
    }
    const onResetAndInit = () => {
        console.log('-- onResetAndInit --');
        openFullScreen()
        setStatus('check');
    }
    const onStart = () => {
        openFullScreen()
        setStatus('test');
        setTestStartTime( new Date() )
    }
    const onBreak = () => {
        closeFullScreen()
        setStatus('stop');
    }
    const onEnd = (report) => {
        closeFullScreen()
        setStatus('end');
        sendResult(report, new Date() - testStartTime )
    }

    const onFocused = (e) => {
        // console.log('Focused');
    }
    const onUnfocused = (e) => {
        // console.log('Unfocused');
        if (!Debug.is) {
            onBreak();
        }
    }
    const onFullscreenChange = (e) => {
        if (!document.fullscreenElement) { // close fullscreen
            if (status === 'start' || status === 'test' || status === 'check') {
                console.log('close fullscreen')
                onBreak();
            }
        }
    }
    const onKeyDown = (e) => {
        switch (status) {
            case 'start': 
                switch (e.key) {
                    case 'Escape': 
                        onBreak();  
                        break;
                    case 'Enter': 
                    case ' ': 
                        onStart();  
                        break;
                    default: 
                        break;
                }
                break;
            case 'test': 
            case 'check': 
                switch (e.key) {
                    case 'Escape': 
                        onBreak();  
                        break;
                    case 'Enter': 
                    case ' ': 
                        // onMainUserClick();  
                        break;
                    default: 
                        break;
                }
                break;
            case 'stop':
            case 'end':
            default:        
                break;
        }
    }
    
    useEffect(() => {
        window.addEventListener('focus', onFocused)
        window.addEventListener('blur', onUnfocused)
        document.addEventListener('keydown', onKeyDown)
        document.addEventListener('fullscreenchange', onFullscreenChange)
        return () => {
            window.removeEventListener('focus', onFocused)
            window.removeEventListener('blur', onUnfocused)
            document.removeEventListener('keydown', onKeyDown)
            document.removeEventListener('fullscreenchange', onFullscreenChange)
        }
    })

    useEffect(() => {
        onResetAndInit()
    },[])

    switch (status) {
        case 'check': return <CompCheckPageContent onGood={()=>onGood()} />
        case 'start': return <StartPageContent onStart={()=>onStart()} />
        case 'test': return <TestPageContent test={test} onFinishTest={onEnd} resetKey={status} />
        case 'stop': return <StopPageContent onReset={()=>onResetAndInit()} />
        case 'end': return <EndPageContent />
        default:
            break;
    }
}
