

import './TestDisplay.css'


export default function TestDisplay({text, error = false, type = ''}) {
    if (text === '') {
        return (
            <div className="test-display"></div>
        )
    }
    if (text.length % 2 === 0) {
        text = <>{text}&nbsp;</>
    }
    let classes = 'frame';
    if (error) classes += ' frame-error';
    if (type) classes += ' frame-' + type;
    return (
        <div className="test-display">
            <div className={ classes }>
                {text}
            </div>
        </div>
    )
}