
import ClientsOfficeView from './../../viewports/ClientsOfficeView'
import React, { useEffect, useState } from 'react';
import { DataGrid, ruRU } from '@material-ui/data-grid';
import { ruRU as coreRuRU } from '@material-ui/core/locale';
import api from '../../api/api';
import { Link, Breadcrumbs, Typography, Button, Switch, TextField, Card, CardContent, Grid } from '@material-ui/core';
import { Link as RouteLink, useHistory } from "react-router-dom";
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { personDeactivation } from './PersonDetailPage'
import SelectTestAndCreateAssignmentBtn from '../../components/SelectTestAndCreateAssignmentBtn'
import PrintPersonBtn from '../../components/PrintPersonBtn';
import { useSnackbar } from 'notistack';
import Debug from '../../components/Debug';
import LoadCsvBtn from '../../components/LoadCsvBtn';


const muiTheme = createMuiTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  ruRU,
  coreRuRU
);

const useStyles = makeStyles(
  (theme) => {
    const isDark =(theme.palette.type || theme.palette.mode) === 'dark';

    return {
      root: {
        '& .MuiDataGrid-cellEditing': {
          backgroundColor: 'rgb(255,215,115, 0.19)',
          color: '#1a3e72',
        },
        '& .Mui-error': {
          backgroundColor: `rgb(126,10,15, ${isDark ? 0 : 0.1})`,
          color: isDark ? '#ff4343' : '#750f0f',
        },
        '& .MuiButton': {
          textTransform: 'none !important'
        }        
      },
    };
  },
  { muiTheme },
);
  
export default function PersonListPage(props) {
    const [persons, setPersons] = useState([])
    const [searchText, setSearchText] = useState('')
    const [new_person_name, set_new_person_name] = useState('')
    const [new_person_number, set_new_person_number] = useState('')
    const history = useHistory()
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(25);
    const [indexUpdate, setIndeUpdate] = useState(0)
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const onBlock = (e, elem) => {
      personDeactivation(elem.row).then(() => {
        setIndeUpdate(indexUpdate + 1)
      })
      e.stopPropagation()
    }

    const selectionModelOnlyActivePersons = selectionModel.filter(id => persons.find(p=>p.id === id && p.active === true ))
  
    const columns = [
        { field: 'id', headerName: 'ID', width: 300, hide: true },
        { field: 'customNumber', headerName: 'Таб.№', width: 120, editable: false, 
            sortComparator: (v1, v2, cellParams1, cellParams2) => parseInt(v1) - parseInt(v2),
          },
        { field: 'description', headerName: 'Описание', width: 300, editable: true },
        { field: 'login', headerName: 'Логин', width: 130, editable: false },
        { field: 'password', headerName: 'Пароль', width: 130, editable: false },
        { field: 'active', headerName: 'Статус', flex: 1, renderCell: (params) => (<>
            <Switch color="primary" checked={params.value}
              onClick={e => onBlock(e, params)} />
            { params.value === true ? 'активный' : 'блокированный'}
          </>)}, 
        { field: 'actions', headerName: 'Детали', width: 150, renderCell: (params) => (
              <Button variant="contained" color="primary" size="small"
                component={RouteLink} to={"/personlist/" + params.id}
                onClick={(e)=>{e.stopPropagation()}}>
                  Детали
              </Button>
          ),},
    ];

    const validateCustomNumber = (self_id, customNumberStr) => {
      if ( customNumberStr === '' ) {
        return { bool: false, help: 'Табельный № пустой' }
      } else if ( !(/^[0-9]+$/).test(customNumberStr) ) {
        return { bool: false, help: 'Табельный № должен быть числом' }
      } else if ( customNumberStr[0] === '0' ) {
        return { bool: false, help: 'Табельный № не должен начинаться с нуля' }
      } else if ( persons.filter(p => parseInt(p.customNumber) === parseInt(customNumberStr) && p.id !== self_id && p.active !== false).length > 0 ) {
        return { bool: false, help: 'Такой табельный № уже существует' }
      } else {
        return { bool: true, help: '' }
      }
    }

    useEffect(() => {
      api.get_org_persons().then(
          res => setPersons(res),
          err => {}
      )
    }, [indexUpdate, history])


    const on_commit = ({field, id, props}) => {
      console.log('on_commit', field, id, props)
      if (field === 'description') {
        let current = persons.find(x => x.id === id)
        if ( current && current.description === props.value) {
          return
        }
        api.set_org_person_desc(id, props.value).then(res=> {
          enqueueSnackbar('Поле "Описание" обновлено', { 
            variant: 'success',
          })
        }, err => {})
      }
    }

    const on_create_person = () => {
      if (!validateCustomNumber('',new_person_number).bool) {
        enqueueSnackbar(validateCustomNumber('',new_person_number).help, { 
          variant: 'error',
        })
        return;
      }
      api.create_tested_person(new_person_number, new_person_name).then(() => {
        setIndeUpdate(indexUpdate + 1);
        set_new_person_name('')
        set_new_person_number('')
        document.getElementById("new_person_number").focus();
      }, err => {}).then( res => {
        enqueueSnackbar('Участник №'+new_person_number+' '+new_person_name+' добавлен', { 
          variant: 'success',
        })
      }, err => {})
    }

    const searchFilterperson = () => {
      if (searchText === '') return persons
      return persons.filter(x => 
        x.description.toLocaleLowerCase().search(searchText.toLocaleLowerCase()) > -1 || 
        x.customNumber.toString().search(searchText) > -1
      )
    }

    return (
        <ClientsOfficeView>
            <Breadcrumbs aria-label="breadcrumb">
              <Link component={RouteLink} color="inherit" to="/dashboard" >
                Главная
              </Link>
              <Typography color="textPrimary">Участники</Typography>
            </Breadcrumbs>

            <Grid container alignItems="center">
                <Grid item xs>
                    <h1>Список тестируемых участников</h1>
                </Grid>
                <Grid item xs style={{textAlign:'right'}}>
                    <TextField label="Поиск" variant="outlined" size="small" 
                        value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                </Grid>
            </Grid>
            

            <Card>
              <CardContent>

                <div style={{display:'flex', justifyContent:'space-between'}}>
                  { selectionModel.length > 0 ? 
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                      <SelectTestAndCreateAssignmentBtn ids={selectionModelOnlyActivePersons} >
                        Назначить тест ({selectionModelOnlyActivePersons.length})
                      </SelectTestAndCreateAssignmentBtn>
                      <PrintPersonBtn ids={selectionModelOnlyActivePersons} persons={persons}>
                        Печать...  ({selectionModelOnlyActivePersons.length})
                      </PrintPersonBtn>
                    </div>
                    : 

                      <div style={{display:'flex'}}>
                        <TextField id="new_person_number" label="Таб.№ (*)" variant="outlined" size="small" 
                            onKeyPress={(e)=>{ if (e.key === 'Enter') { document.getElementById("new_person_desc").focus(); } }}
                            helperText={new_person_number !== '' && validateCustomNumber('',new_person_number).help} 
                            error={!validateCustomNumber('',new_person_number).bool && new_person_number !== ''  }
                            value={new_person_number} 
                            onChange={(e) => set_new_person_number(e.target.value)} />

                        &nbsp;
                        &nbsp;

                        <TextField id="new_person_desc" label="Описание" variant="outlined" size="small" style={{width:"300px"}}
                            onKeyPress={(e)=>{ if (e.key === 'Enter') { on_create_person(); } }}
                            value={new_person_name} onChange={(e) => set_new_person_name(e.target.value)} />

                        &nbsp;
                        &nbsp;
                        
                        <Button variant="contained" color="primary" onClick={()=>on_create_person()} >Добавить участника</Button>
                        {/* style={{
                              height: '1.1876em',
                              boxSizing: 'content-box',
                              paddingTop: '11.5px',
                              paddingBottom: '11.5px',
                        }} */}
                      </div>
                  }

                  <div style={{display:'flex'}}>
                    &nbsp;
                    &nbsp;
                    <Button variant="contained" color="primary" onClick={()=>setIndeUpdate(indexUpdate + 1)}>Обновить</Button>
                    &nbsp;
                    &nbsp;
                    <LoadCsvBtn persons={persons} onChange={() => setIndeUpdate(indexUpdate + 1)} /> 
                  </div>
                </div>
                <br/>

                <ThemeProvider theme={muiTheme}>
                  <DataGrid rows={searchFilterperson()} columns={columns} 
                    disableColumnMenu
                    checkboxSelection 
                    selectionModel={selectionModel}
                    onSelectionModelChange={(e) => {setSelectionModel(e.selectionModel)}}
                    autoHeight={true}
                    pageSize={pageSize}
                    onPageSizeChange={e => setPageSize(e.pageSize)}
                    pagination
                    disableSelectionOnClick
                    className={classes.root}
                    sortModel={[{ field: 'customNumber', sort: 'desc' }]}
                    sortingOrder={['desc', 'asc']}
                    onEditCellChangeCommitted={on_commit}
                  />
                </ThemeProvider>

              </CardContent>
            </Card>


            <Debug json={persons} />
        </ClientsOfficeView>
    )
}


