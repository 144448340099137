
import TestView from './../../../viewports/TestView'
import TestDisplay from '../../../components/TestDisplay';
import { useEffect, useState } from 'react';

function toFragments(testdata) {
    let StimulusFlatFullList = testdata.Clusters.flatMap((c,i)=>[ 
        ...c.Stimulus.map((s,ii) => ({
            ...s, 
            ClusterIndex:i, 
            StimulusIndex:ii
        }))
    ]) 
    return StimulusFlatFullList.map(s => {
        let subFragments = [];
        let before_delay_ms = 300 + Math.round( Math.random() * 450 )
        subFragments.push({
            fragmentType: 'BEFORE_DELAY',
            text: '',
            duration: before_delay_ms,
            // duration: 2000,
        })
        switch(s.Variant) {
            
            // 0 – стимул предъявляется в немаскированном виде; 
            // при появлении этого стимула на экране монитора испытуемый 
            // не должен совершать никаких действий (нажимать на экран).
            case 0: 
                subFragments.push({
                    fragmentType: 'WORD_FOR_VIEW',
                    text: s.Stimulus,
                    duration: 2000,
                    // duration: 5000,
                })
                break;

            // 1 – стимул предъявляется в маскированном виде; 
            // при его появлении на экране испытуемый должен успеть отреагировать 
            // (нажать на экран) в отведенное для этого время.
            case 1:
                subFragments.push({
                    fragmentType: 'SECRET',
                    text: s.Stimulus,
                    duration: 17,
                    // duration: 300,
                    // duration: 1000,
                })
                subFragments.push({
                    fragmentType: 'MASK',
                    text: s.Mask,
                    duration: 837,
                    // duration: 850,
                    // duration: 5000,
                })
                break;


            // 2 – стимул предъявляется в немаскированном виде; 
            // при его появлении на экране испытуемый должен успеть отреагировать 
            // (нажать на экран) в отведенное для этого время
            case 2:
                subFragments.push({
                    fragmentType: 'WORD_FOR_ACTION',
                    text: s.Stimulus,
                    duration: 2000,
                })
                break;

            default:
                break;
        }
        return subFragments.map((f,i) => ({
            ...f,
            ClusterIndex: s.ClusterIndex,
            StimulusIndex: s.StimulusIndex,
            FragmentIndex: i,
            before_delay_ms: before_delay_ms,
            Stimulus: s.Stimulus,
            Variant: s.Variant,
        }))
    }).flat()
}




export default function TestPageContent({test, onFinishTest, resetKey}) {

    const fullFragmentList = toFragments( test )
    const [activeFragmentIndex, setActiveFragmentIndex] = useState(0)

    const [errText, setErrText] = useState('')
    const [isShowError, setIsShowError] = useState(false)

    const [errCount, setErrCount] = useState(0)
    const [report, setReport] = useState([])

    useEffect(() => {
        console.log('RESET AND INIT TEST')
        // from  onResetAndInit()
        setActiveFragmentIndex(0)
        setReport([])
        setErrCount(0)
        setIsShowError(false)
        setErrText('')
    },[resetKey])


    const onErrInVain = (fragment, time) => {
        showError('ERR_IN_VAIN', time, 'ЗРЯ!', '/sounds/in_vain.wav', fragment)
    }
    const onErrToEarly = (fragment, time) => {
        showError('ERR_TO_EARLY', time, 'РАНО!', '/sounds/too_early.wav', fragment)
    }
    const onErrToLate = (fragment, time) => {
        showError('ERR_TO_LATE', time, 'ПОЗДНО!', '/sounds/too_late.wav', fragment)
    }
    const onErrNeed = (fragment, time) => {
        showError('ERR_NEED', time, 'НАДО БЫЛО ЖАТЬ!', '/sounds/need.wav', fragment)
    }
    const showError = (type_of_er, time, txt, sound_url, fragment) => {
        new Audio(sound_url).play()
        setErrText(txt);
        setIsShowError(true);

        let actialReport = [ 
            ...report, 
            { 
                res: type_of_er, 
                time: time, 
                ...fragment, 
                err_text: txt 
            } 
        ]
        
        setReport( actialReport )
        // console.log(actialReport)

        if (errCount < 40) {
            setErrCount(errCount + 1)
            reverse_to_begining_stimut(fragment)
        } else {
            setErrCount(0)
            reverse_to_begining_cluster(fragment)
        }
    }

    const reverse_to_begining_stimut = (fragment) => {
        setActiveFragmentIndex(activeFragmentIndex - fragment.FragmentIndex)
    }

    const reverse_to_begining_cluster = (fragment) => {
        let index = fullFragmentList.findIndex(x => x.ClusterIndex === fragment.ClusterIndex && x.StimulusIndex === 0 && x.FragmentIndex === 0)
        setActiveFragmentIndex(index)
    }


    const right_wait = (fragment, time) => {
        return right_end_fragment('right_wait', fragment, time)
    }
    const right_click = (fragment, time) => {
        return right_end_fragment('right_click', fragment, time)
    }
    const right_end_fragment = (type_of_end, fragment, time) => {
        
        let actialReport = report;

        switch(fragment.fragmentType) {
            case 'BEFORE_DELAY':   
            case 'SECRET':         
                break;
            case 'MASK':         
                let actialReport = [ 
                    ...report, 
                    { 
                        res: type_of_end, 
                        time: time, 
                        ...fragment, 
                    } 
                ]
                setReport( actialReport )
                break;
            case 'WORD_FOR_ACTION':
            case 'WORD_FOR_VIEW':  
            default:
                break
        }

        
        if (activeFragmentIndex === fullFragmentList.length -1) {
            console.log('FINISH TEST', fullFragmentList.length, activeFragmentIndex)
            onFinishTest( actialReport );
        }

        if ( fullFragmentList[activeFragmentIndex].ClusterIndex === 0) {
            setErrCount(0)
        }
        setActiveFragmentIndex(activeFragmentIndex + 1)
    }


    const onMainUserClick = (e, fragment, time) => {
        console.log('CLICK', activeFragmentIndex, fragment.fragmentType, fragment.text, time)

        switch(fragment.fragmentType) {
            case 'BEFORE_DELAY':        return onErrToEarly(fragment, 0); // time - fragment.before_delay_ms
            case 'SECRET':              return onErrToEarly(fragment, time);
            case 'MASK':
                let time_fix = time + 17; // поправка, для учета времени на фрагмент SECRET
                if (time_fix < 120) {       return onErrToEarly(fragment, time_fix); } 
                else if (time_fix > 840) {  return onErrToLate(fragment, time_fix); } 
                else {                  return right_click(fragment, time_fix) }

            case 'WORD_FOR_ACTION':     return right_click(fragment, time)
            case 'WORD_FOR_VIEW':       return onErrInVain(fragment, time)
            default:                    return;
        }
    }

    const onEndFragment = (fragment) => {
        console.log('END', activeFragmentIndex, fragment.text)

        switch(fragment.fragmentType) {
            case 'BEFORE_DELAY':    return right_wait( fragment, fragment.duration )
            case 'SECRET':          return right_wait( fragment, fragment.duration )
            case 'MASK':            return onErrToLate( fragment, fragment.duration + 17 )
            case 'WORD_FOR_ACTION': return onErrNeed( fragment, fragment.duration )
            case 'WORD_FOR_VIEW':   return right_wait( fragment, fragment.duration )
            default:                return;
        }
    }    

    if (isShowError) {
        return <TestFragmentPageContent 
                    text={errText} 
                    duration={2000}
                    isError={true} 
                    onEndTimeout={() => setIsShowError(false)} 
                />
    } else {
        let curFragment = fullFragmentList[ activeFragmentIndex ]
        return <TestFragmentPageContent 
                    text={curFragment.text}
                    duration={curFragment.duration}
                    type={curFragment.fragmentType}
                    isError={false}
                    onMainClick={(e,ts) => onMainUserClick(e, curFragment, ts)} 
                    onEndTimeout={() => onEndFragment( curFragment )} 
                />
    }

}



var start_ts = null;

function TestFragmentPageContent({onMainClick = ()=>{}, onEndTimeout = ()=>{}, duration = 2000, text = '', isError = false, type}) {
    useEffect(() => {
        start_ts = new Date()

        let iid = setTimeout(() => {
            onEndTimeout()
        }, duration)
        return () => {
            clearTimeout(iid)
        }
    })
    return (
        <TestView onMouseDown={(e) => onMainClick(e, new Date() - start_ts)} >
            <TestDisplay text={text} error={isError} type={type} />
        </TestView>
    )
}


