
import React, { useEffect, useState } from 'react';

import {
    Avatar,
    Button,
    Card,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemSecondaryAction,
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton'; 
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Pagination } from '@material-ui/lab';
import Debug from './Debug'



export default function CommonList({
    dataLoader, 
    itemRender, 
    title = x=>{}, 
    desc = x=>{}, 
    actions = x=>{}, 
    onSelect, 
    icon = x=>{}, 
    indexUpdate = 0, 
    perPage=5,
    ...rest
}) {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(0)
    const [itemPerPage, setItemPerPage] = useState(perPage)

    useEffect(() => {
        setLoading(true)
        dataLoader().then(
            res => {
                setList(res)
                setPageNumber(0)
                setLoading(false)
            },
            err => {
                setPageNumber(0)
                setLoading(false)
            }
        )
        return () => {}
    }, [dataLoader, indexUpdate])

    if (loading && list.length === 0) {
        return (
            <div style={{textAlign:'center'}}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </div>
        )
    }
    if (list.length === 0) {
        return (
            <div style={{textAlign:'center'}}>
                <p style={{color:'grey'}}><small>Список пуст</small></p>
            </div>
        )
    }

    return (
        <>
            <Card {...rest}>
                <List>
                    {list.slice( pageNumber * itemPerPage, pageNumber * itemPerPage + itemPerPage ).map(x => (
                        <ListItem button={onSelect ? true : false} key={x.id} onClick={()=>onSelect ? onSelect(x) : ()=>{}}>
                            { itemRender ? itemRender(x) : <>
                                <ListItemAvatar>
                                    <Avatar>
                                        { icon ? icon(x) : <AssignmentIcon /> }
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={title(x)} 
                                    secondary={desc(x)} 
                                    style={{width:"0"}} 
                                />
                                <ListItemSecondaryAction>
                                    {actions(x)}
                                </ListItemSecondaryAction>
                            </> }
                        </ListItem>
                    ))}
                </List>
            </Card>
            { list.length > itemPerPage ?
                (
                    <div style={{display:'flex', justifyContent:'space-between', paddingTop:'10px'}}>
                        <Pagination count={Math.ceil(list.length/itemPerPage)} onChange={(e,n) => setPageNumber(n-1)} />
                        <div style={{width:'200px', textAlign:'center'}}>
                            <Button onClick={()=>setItemPerPage(1000)}>Показать все ({list.length})</Button>
                        </div>
                    </div>
                ) : ''
            }
            <Debug json={list} />
        </>
    );
}