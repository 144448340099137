
import { useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import CoverView from '../../viewports/CoverView'
import api from '../../api/api'
import Debug from '../../components/Debug'


export default function LoginPage({cover, logo, onLogin=()=>{}}) {
    const [login, setLogin] = useState(Debug.is ? 'softsrv' : '')
    const [pass, setPass] = useState(Debug.is ? 'softsrv' : '')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const auth = () => {
        if (loading || !login || !pass) return;
        setLoading(true)
        api.org_auth(login, pass).then(res => {
            setLoading(false)
            setError('')
            onLogin(res)
        }, err => {
            setLoading(false)
            setError('Username or password does not match')
        })
    }
    return (
        <CoverView>
            <h1>Sign in</h1>
            <TextField id="login" label="Login" variant="outlined" size="small" error={error !== ''}
                value={login} onChange={(e) => setLogin(e.target.value)} />
            <br/>
            <TextField id="pass" label="Password" variant="outlined" size="small" helperText={error} error={error !== ''} type="password"
                value={pass} onChange={(e) => setPass(e.target.value)}
                onKeyPress={e => { if (e.code === 'Enter') auth() }} />
            <br/>
            
            <Button size="large" variant="contained" color="secondary" onClick={() => auth()}>
                { loading ? '...' : 'Sign in' }
            </Button>
        </CoverView>
    )
}