
import React, { useEffect, useState } from 'react';
import { Button, Avatar, List, ListItem, ListItemAvatar, ListItemText, DialogTitle, DialogContent,  Dialog, TextField, ListItemIcon, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useSnackbar } from 'notistack';
import api from '../api/api';
import Debug from './Debug';
import moment from 'moment'


function addDays(days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date.toISOString().substr(0,10);
}

export function SelectTestDialog({ onClose, onSelectTest, open, title }) {
    const [tests, setTests] = useState([])
    const [selectedDate, setSelectedDate] = React.useState(addDays(14));
    const [checked, setChecked] = React.useState([]);

    const handleClose = () => {
      onClose();
    };

    useEffect(() => {
        api.get_org_tests().then(
            res => setTests(res),
            err => {}
        )
    }, [])

    const handleToggle = (value) => {
        console.log('handleToggle', value)
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
      };

    const handleListItemClick = (testlist) => {
        if (testlist.length === 0) return
        onSelectTest(testlist, selectedDate)
        onClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="select-test-dialog-title" open={open}>
            <DialogTitle id="select-test-dialog-title">{title}</DialogTitle>

            <DialogContent>
                <TextField
                    
                    style={{width:'100%'}}
                    id="date"
                    label="Дата окончания теста"
                    type="date"
                    onChange={(e) => setSelectedDate(e.target.value)}
                    value={selectedDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </DialogContent>

            <List style={{minWidth:'350px'}}>
                {tests.map((test) => (
                <ListItem button onClick={() => handleToggle(test.id)} key={test.id}>
                    <ListItemIcon>
                        <Checkbox
                            style={{marginLeft:'5px',marginRight:'10px'}}
                            edge="start"
                            checked={checked.indexOf(test.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                        />
                    <ListItemAvatar>
                        <Avatar>
                            <AssignmentIcon />
                        </Avatar>
                    </ListItemAvatar>
                    </ListItemIcon>
                    <ListItemText primary={test.description} secondary={Debug.is ? test.id : ''} />
                </ListItem>
                ))}
            </List>

            <DialogContent>
                <Button variant="contained" disabled={checked.length === 0} color="primary" onClick={() => handleListItemClick(checked)}>
                    Назначить
                </Button>
                <br/>
                <br/>
            </DialogContent>
        </Dialog>
    );
}
  
SelectTestDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
  
export default function SelectTestAndCreateAssignmentBtn({ids = [], children, disabled = false, onChange=()=>{}}) {
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
  
    const handleClickOpen = () => {
        if ( ids.length === 0) return
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };
  
    const handleSelect = (testListIds, datetime) => {

        if (testListIds.length === 0) return
        console.log('select test', testListIds, ids)

        let end_date = moment(datetime).format('DD.MM.YYYY 12:00:00')

        api.create_assignments(testListIds, ids, end_date).then(res => {
            enqueueSnackbar('Тесты назначены', { 
                variant: 'success',
            })
            onChange()
        }, err => { // err.data.detail
            console.log('err', err)
            enqueueSnackbar('Назвачить не удалось', { 
                variant: 'error',
            })
            onChange()
        })
    };
   
    return (
      <>
        <Button variant="contained" color="primary" onClick={handleClickOpen} disabled={disabled}>
          {children}
        </Button>

        <SelectTestDialog open={open} onClose={handleClose} onSelectTest={handleSelect} title={children} />
  
        &nbsp;
        &nbsp;
      </>
    );
}
  