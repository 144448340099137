
import real_axios from 'axios'
import moment from 'moment';
import Debug from '../components/Debug';
import demo_axios from './demodata'

let api_server = null;
let axios = null;

let user_auth_token = null;
let user_login = null;
let user_type = null;


// переменная window.CONFIG объявляется в файле /config.js, подключаемый в index.html

if (window.CONFIG.api_demo_mode) {
    api_server = '';
    // demo_axios.set_timeout(0);
    axios = demo_axios;
} else {
    api_server = window.CONFIG.api_server;
    axios = real_axios;
}

axios.defaults.headers.common = {
    'Content-Type': 'application/json',
}

const _join_assig_test_wrapper = (res) => {
        return api.get_org_tests().then(
            tests => {
                if (Debug.is) return res.map( x => ({
                    ...x,
                    test: tests[0],
                }))

                return  res.map( x => ({
                    ...x,
                    test: tests.find(t => t.description === x.testDescription),
                }))
            },
        )
}
const _join_assig_person_wrapper = (res) => {
        return api.get_org_persons().then(
            persons => {
                if (Debug.is) {
                    return  res.map( x => ({
                        ...x,
                        person: persons[0],
                    }))
                }
                return  res.map( x => ({
                    ...x,
                    person: persons.find(p => p.customNumber === x.testedPersonCustomNumber),
                }))
            },
        )
}

const _sort_by_datetime_str = (arr = [], key = 'passedMoscow') => {
    return arr.sort( (a,b) => 
        moment(b[key], 'DD.MM.YYYY HH:mm:ss') - moment(a[key], 'DD.MM.YYYY HH:mm:ss') 
    );
}

const API_EVENT_MIXIN = {
	_eventHandels: [],
	addEventListener(eventName, handel) {
		this._eventHandels.push({
			eventName: eventName,
			handel: handel,
		})
	},
	removeEventListener(eventName, handel) {
		this._eventHandels = this._eventHandels.filter(x => x.eventName !== eventName || x.handel !== handel);
	},
	dispatchEvent(eventName, event) {
		this._eventHandels.filter(x => x.eventName === eventName).forEach( e => e.handel(event) )
	},
}

const unauthError = () => {
    let e = new Error('Для запроса нужна авторизация');
    e.response = {
        status: '401',
        statusText: 'UNAUTHORIZED' 
    }
    return e
}



let api = {

    ...API_EVENT_MIXIN,

    EVENT_UNAUTH_TESTED_PERSON_REQUEST: 'EVENT_UNAUTH_TESTED_PERSON_REQUEST',
    EVENT_UNAUTH_CLIENT_REQUEST: 'EVENT_UNAUTH_CLIENT_REQUEST',

    USER_TYPE_ORGANIZATION: 'USER_TYPE_ORGANIZATION',
    USER_TYPE_TESTED_PERSON: 'USER_TYPE_TESTED_PERSON',

    isAuth() {
        return user_auth_token ? true : false
    },
    isAuthClient() {
        return this.isAuth() && user_type === this.USER_TYPE_ORGANIZATION ? true : false
    },
    isAuthTested() {
        return this.isAuth() && user_type === this.USER_TYPE_TESTED_PERSON ? true : false
    },

    onErrorAuthClient() {
        return this.onError( unauthError(), this.USER_TYPE_ORGANIZATION );
    },
    onErrorAuthTested() {
        return this.onError( unauthError(), this.USER_TYPE_TESTED_PERSON );
    },

    onError(err, user_type_request) {
        user_type_request = user_type_request || user_type
        if (Debug.is) { console.log('ON ERROR', err, err.response, user_type_request) }
        if (err?.response?.statusText === 'UNAUTHORIZED' || err?.response?.status === 401) {    
            switch (user_type_request) {
                case this.USER_TYPE_TESTED_PERSON:
                    API_EVENT_MIXIN.dispatchEvent(this.EVENT_UNAUTH_TESTED_PERSON_REQUEST, err);
                    break;
    
                case this.USER_TYPE_ORGANIZATION:
                default:
                    API_EVENT_MIXIN.dispatchEvent(this.EVENT_UNAUTH_CLIENT_REQUEST, err);
                    break;
            }
        }
        return Promise.reject( err )
    },


    org_auth(login = 'softsrv', pass = 'softsrv') {
        return axios.post( api_server + '/organization/authentication', {
            "login": login,
            "password": pass,
        }).then(res => {
            if (Debug.is) { console.log(res) }
            if ( res.data && res.data.authData && res.data.authData.token) {
                user_login = login
                user_type = this.USER_TYPE_ORGANIZATION
                user_auth_token = res.data.authData.token
                axios.defaults.headers.common = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user_auth_token,
                }
            } else {
                return Promise.reject(false)
            }
        }, er => {
            if (Debug.is) { console.log(er) }
            return false
        })
    },

    person_auth(login = 'softsrv', pass = 'softsrv') {
        return axios.post( api_server + '/tested-person/authentication', {
            "login": login,
            "password": pass,
        }).then(res => {
            if (Debug.is) { console.log('person_auth res', res) }
            if ( res.data && res.data.token) {
                user_login = login
                user_type = this.USER_TYPE_TESTED_PERSON
                user_auth_token = res.data.token
                axios.defaults.headers.common = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user_auth_token,
                }
            } else {
                return Promise.reject(false)
            }
        }, er => {
            if (Debug.is) { console.log('person_auth err', er) }
            return false
        })
    },

    //
    // OrganizationInfo – Методы работы с информацией об организации
    //

    get_org_info() {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization').then(res => {
            if (Debug.is) { console.log('get_org_info res', res) }
            return {
                name: res.data.name
            }
        }, this.onError)
    },

    set_org_info(name) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.patch( api_server + '/organization', {
            name: name
        }).then(res => {
            if (Debug.is) { console.log('set_org_info res', res) }
        }, this.onError)
    },

    get_org_logo() {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        const getMimeType = (blob) => {
            if ( blob.indexOf('PNG') > -1 ) return 'image/png'
            if ( blob.indexOf('JFIF') > -1 ) return 'image/jpeg'
            if ( blob.indexOf('GIF') > -1 ) return 'image/gif'
            if ( blob.indexOf('SVG') > -1 || blob.indexOf('svg') > -1 ) return 'image/svg+xml'
        }
        return axios.get( api_server + '/organization/logo').then(res => {
            if (Debug.is) { console.log('get_org_logo res', res) }
            let logo_base64 = res.data.data
            if (logo_base64 === null) { 
                return Promise.reject() 
            }
            return {
                logoUrl: 'data:' + getMimeType(atob(logo_base64)) + ';base64,' + logo_base64
            }
        }, this.onError)
    },

    get_person_logo() {
        if (!this.isAuthTested()) return this.onErrorAuthTested();
        const getMimeType = (blob) => {
            if ( blob.indexOf('PNG') > -1 ) return 'image/png'
            if ( blob.indexOf('JFIF') > -1 ) return 'image/jpeg'
            if ( blob.indexOf('GIF') > -1 ) return 'image/gif'
            if ( blob.indexOf('SVG') > -1 || blob.indexOf('svg') > -1 ) return 'image/svg+xml'
        }
        return axios.get( api_server + '/tested-person/logo').then(res => {
            if (Debug.is) { console.log('get_person_logo res', res) }
            let logo_base64 = res.data.data
            if (logo_base64 === null) { 
                return Promise.reject() 
            }
            return {
                logoUrl: 'data:' + getMimeType(atob(logo_base64)) + ';base64,' + logo_base64
            }
        }, this.onError)
    },

    set_org_logo(logo_blob) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsBinaryString(logo_blob);
            reader.onload = function() {
                let logo_base64 = btoa(reader.result);

                axios.post( api_server + '/organization/logo', {
                    data: logo_base64
                }).then(res => {
                    if (Debug.is) { console.log('set_org_logo res', res) }
                    resolve(res)
                }, this.onError)
            };
        })
    },

    //
    // OrganizationTestedPersons – Методы работы тестируемыми организации
    //

    create_tested_person(id = '234', desc = 'fio') {
        return this.create_tested_person_multi([{
            customNumber: id,
            description: desc,
        }])
    },

    create_tested_person_multi( person_list ) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.post( api_server + '/organization/tested-persons', {
            testedPersons: person_list
        }).then(res => {
            if (Debug.is) { console.log('create_tested_person_multi res', res) }
            return res
        }, this.onError)
    },

    get_org_persons() {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization/tested-persons', {
            params: {
                onlyActive: false,
                // searchString::str
                // skip::int
                // take::int
            }
        }).then(res => {
            if (Debug.is) { console.log('get_org_persons res', res) }
            return res.data.persons
        }, this.onError)
    },

    get_org_person_detail(testedPersonId) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization/tested-persons/' + testedPersonId).then(res => {
            if (Debug.is) { console.log('get_org_person_detail res', res) }
            return res.data.testedPerson
        }, this.onError)
    },

    set_org_person_desc(testedPersonId, desc) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.patch( api_server + '/organization/tested-persons/' + testedPersonId, {
            description: desc,
        }).then(res => {
            if (Debug.is) { console.log('set_org_person_desc res', res) }
            return res
        }, this.onError)
    },

    get_org_person_assignments_active(testedPersonId) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization/tested-persons/' + testedPersonId + '/assignments/active').then(res => {
            if (Debug.is) { console.log('get_org_person_assignments_active res', res) }
            return _join_assig_test_wrapper( _sort_by_datetime_str( res.data.assignments || [], 'expiresMoscow' ) )
        }, this.onError)
    },

    get_org_person_assignments_expired(testedPersonId) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization/tested-persons/' + testedPersonId + '/assignments/expired').then(res => {
            if (Debug.is) { console.log('get_org_person_assignments_expired res', res) }
            return _join_assig_test_wrapper( _sort_by_datetime_str( res.data.assignments || [], 'expiresMoscow' ) )
        }, this.onError)
    },

    get_org_person_assignments_results(testedPersonId) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization/tested-persons/' + testedPersonId + '/assignments/results').then(res => {
            if (Debug.is) { console.log('get_org_person_assignments_results res', res) }
            return _join_assig_test_wrapper( _sort_by_datetime_str( res.data.results || [], 'passedMoscow' ) )
        }, this.onError)
    },

    get_org_person_deactivation(testedPersonId) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.patch( api_server + '/organization/tested-persons/' + testedPersonId + '/deactivation').then(res => {
            if (Debug.is) { console.log('get_org_person_deactivation res', res) }
            return res.data
        }, this.onError)
    },

    //
    // OrganizationTests - методы работы с тестами организации
    // 

    get_org_tests() {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization/tests', {
            params: {
                // onlyActive: false,
                // searchString::str
                // skip::int
                // take::int
            }
        }).then(res => {
            if (Debug.is) { console.log('get_org_tests res', res) }
            return res.data.tests
            
        }, this.onError)
    },

    get_org_test_detail(testId) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization/tests/' + testId, {}).then(res => {
            if (Debug.is) { console.log('get_org_test_detail res', res) }
            return res.data.test
        }, this.onError)
    },

    get_org_test_assignments_active(testId) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization/tests/' + testId + '/assignments/active', {}).then(res => {
            if (Debug.is) { console.log('get_org_test_assignments_active res', res) }
            return _join_assig_person_wrapper( _sort_by_datetime_str( res.data.assignments || [], 'expiresMoscow' ) )
        }, this.onError)
    },

    get_org_test_assignments_expired(testId) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization/tests/' + testId + '/assignments/expired', {}).then(res => {
            if (Debug.is) { console.log('get_org_test_assignments_expired res', res) }
            return _join_assig_person_wrapper( _sort_by_datetime_str( res.data.assignments || [], 'expiresMoscow' ) )
        }, this.onError)
    },

    get_org_test_assignments_results(testId) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization/tests/' + testId + '/assignments/results', {}).then(res => {
            if (Debug.is) { console.log('get_org_test_assignments_results res', res) }
            return _join_assig_person_wrapper( _sort_by_datetime_str( res.data.results || [], 'passedMoscow' ) )
        }, this.onError)
    },

    get_org_test_reports_info() {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization/tests/reports-info', {}).then(res => {
            if (Debug.is) { console.log('get_org_test_reports_info res', res) }
            return res.data
        }, this.onError)
    },

    //
    // OrganizationAssignments – Методы работы с назначениями тестов
    //

    create_assignments(testIds, personsIds, expiresMoscow = '30.07.2021 12:00:00') {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.post( api_server + '/organization/assignments', {
            assignments: testIds.map(tid => ({
                testId: tid,
                expiresMoscow: expiresMoscow,
                testedPersonsToAssign: personsIds
            }))
        }).then(res => {
            if (Debug.is) { console.log('create_assignments res', res) }
            return res
        }, this.onError)
    },

    del_assignments(assignmentId) {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.delete( api_server + '/organization/assignments/' + assignmentId).then(res => {
            if (Debug.is) { console.log('del_assignments res', res) }
            return res
        }, this.onError)
    },

    //
    //  TestedPersons API
    // 

    get_person_assignments() {
        if (!this.isAuthTested()) return this.onErrorAuthTested();
        return axios.get( api_server + '/tested-person/assignments', {}).then(res => {
            if (Debug.is) { console.log('get_person_assignments res', res) }
            return res.data.assignments
        }, this.onError)
    },

    get_person_test_starting(assignmentId) {
        if (!this.isAuthTested()) return this.onErrorAuthTested();
        return axios.post( api_server + '/tested-person/assignments/' + assignmentId + '/starting', {}).then(res => {
            if (Debug.is) { console.log('get_person_test_starting res', res) }
            return JSON.parse( res.data.testPreparedData )
        }, this.onError)
    },

    set_person_test_completion(assignmentId, TestResult) {
        if (!this.isAuthTested()) return this.onErrorAuthTested();
        return axios.post( api_server + '/tested-person/assignments/' + assignmentId + '/completion', {
            resultData: TestResult
        }).then(res => {
            if (Debug.is) { console.log('set_person_test_completion res', res) }
            return res.data.resultData
        }, this.onError)
    },


    //
    //
    //

    get_results(resultId, resultType = 'html') {
        if (!this.isAuthClient()) return this.onErrorAuthClient();
        return axios.get( api_server + '/organization/results/' + resultId, {
            responseType: "arraybuffer",
            params: {
                resultType: resultType, 
            }
        }).then(res => {
            if (Debug.is) { console.log('get_resilts res', res) }
            return res.data
        }, this.onError)
    },

    //
    //
    //

    get_org_branding (org) {
        switch(org) {
            case 'leroymerlin': 
                return {
                    title: 'Leroy Merlin',
                    logo: '/logo_leroy_merlin.svg',
                    bg: '/cover_bg_person.jpg'
                }

            default:
                return false
        }
    },

    get_profile () {
        if (Debug.is) { console.log('get_profile', user_auth_token) }
        if (user_auth_token) {
            return {
                user_login: user_login,
                user_type: user_type,
                user_auth_token: user_auth_token,
                org: 'leroymerlin'
            }
        } else {
            return false
        }
    },
}

export default api