

function Footer() {
    return (
        <div className="footer" style={{textAlign:'center', padding:'40px'}}>
            <small>© {new Date().getFullYear()} PSY Scanner</small>
        </div>
    )
}

export default Footer