
import React, { useState } from 'react';

import api from '../api/api';

import {
    Button,
} from '@material-ui/core';

import CommonList from './CommonList'

import moment from 'moment'
import { useSnackbar } from 'notistack';


export default function AssigListActive(props) {
    const [innerIndexUpdate, setInnerIndeUpdate] = useState(0)
    const { enqueueSnackbar } = useSnackbar()    

    const delAssig = (e, id) => {
        api.del_assignments(id).then(res => {
            setInnerIndeUpdate(innerIndexUpdate + 1)
            enqueueSnackbar('Назначение удалено', { 
                variant: 'success',
              })
        }, err => {
            setInnerIndeUpdate(innerIndexUpdate + 1)
            console.log('delAssig', err)
            enqueueSnackbar('Удалить не получилось', { 
                variant: 'error',
              })
        })
        e.stopPropagation()
    }

    return (
        <CommonList 
            desc={x => moment(x.expiresMoscow, 'DD.MM.YYYY HH:mm:ss').format('D MMM YYYY, HH:mm')} 
            actions={x => <>
                <Button onClick={e => delAssig(e, x.id)} variant="contained" color="secondary">
                    Удалить назначение
                </Button>
            </>}
            {...props}
            indexUpdate={props.indexUpdate + innerIndexUpdate} 
        />
    )
}
