
import React from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useHistory, withRouter } from "react-router";
import { SnackbarProvider } from 'notistack';
import moment from 'moment';
import localization from 'moment/locale/ru';

import ClientLoginPage    from './pages/ClientsOffice/LoginPage'; 
import DashboardPage      from './pages/ClientsOffice/DashboardPage'; 
import ConfigPage         from './pages/ClientsOffice/ConfigPage'; 
import PersonListPage     from './pages/ClientsOffice/PersonListPage'; 
import PersonDetailPage   from './pages/ClientsOffice/PersonDetailPage'; 
import TestListPage       from './pages/ClientsOffice/TestListPage'; 
import TestDetailsPage    from './pages/ClientsOffice/TestDetailsPage'; 
import BrandingLoginPage  from './pages/TestTakersOffice/BrandingLoginPage'; 
import DeskPage           from './pages/TestTakersOffice/DeskPage'; 
import TestPage           from './pages/TestTakersOffice/TestPage'; 
import LogoutPage         from './pages/LogoutPage'; 
import Err404Page         from './pages/Err404Page'; 
import api from './api/api';


moment.locale('ru', localization);


function App() {
  const history = useHistory()

  api.addEventListener(api.EVENT_UNAUTH_TESTED_PERSON_REQUEST, (err) => {
      history.push('/tested-person')
  })
  api.addEventListener(api.EVENT_UNAUTH_CLIENT_REQUEST, (err) => {
      history.push('/organization')
  })

  return (
      <Switch>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}

          <Route path="/organization"><ClientLoginPage onLogin={() => history.push('/dashboard')} /></Route>

          <Route path="/dashboard"><DashboardPage /></Route>
          <Route path="/config"><ConfigPage /></Route>
          <Route path="/personlist/:id"><PersonDetailPage /></Route>
          <Route path="/personlist"><PersonListPage /></Route>
          <Route path="/testlist/:id"><TestDetailsPage /></Route>
          <Route path="/testlist"><TestListPage /></Route>

          <Route path="/tested-person"><BrandingLoginPage onLogin={() => history.push('/desk')} /></Route>
          <Route path="/desk"><DeskPage /></Route>
          <Route path="/test/:id"><TestPage /></Route>

          <Route path="/login"><Redirect to="/organization" /></Route>
          <Route path="/org/:org"><Redirect to="/organization" /></Route>
          <Route path="/org"><Redirect to="/organization" /></Route>
          <Route path="/t"><Redirect to="/tested-person" /></Route>

          <Route path="/logout"><LogoutPage /></Route>
          <Route path="/404"><Err404Page /></Route>
          <Route path="/:any"><Err404Page /></Route>

          <Route path="/"><Redirect to="/organization" /></Route>

      </Switch>
  )
}

const AppWithRouter = withRouter(App)

function AppWrapper() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Router>
        <AppWithRouter />
      </Router>
    </SnackbarProvider>
  )
}

export default AppWrapper;
