
import api from "./api";


function download(data, filename = 'style_transfer.jpg', type = "image/jpeg") {
    let urlCreator = window.URL || window.webkitURL;
  
    var file = new Blob([data], {type: type});
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
            url = urlCreator.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            urlCreator.revokeObjectURL(url);  
        }, 0); 
    }
}

let actions = {
    download_report(reportItem, reportType = 'html') {
        let filetype = reportType === 'csv' ? 'csv' : 'html'
        let filename = 'PSY Scanner. Отчет – Таб.№ '
                + reportItem.testedPersonCustomNumber + ' – '
                + reportItem.testDescription + ' (' + reportType + ')'
        api.get_results(reportItem.id, reportType).then(
            res => {
                console.log(res)
                download(res, filename + '.' + filetype, 'text/' + filetype )
            },
            er => console.log(er)
        )
    },
}

export default actions;