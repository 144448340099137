
import { Redirect } from "react-router-dom";
import api from './../api/api';


export default function LogoutPage() {
    let profile = api.get_profile();

    if ( profile && profile.user_type === api.USER_TYPE_TESTED_PERSON ) {
        return <Redirect to={"/tested-person" } />
    } else {
        return <Redirect to="/organization" />
    }
}