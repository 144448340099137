
import ClientsOfficeView from '../../viewports/ClientsOfficeView'
import React, { useEffect, useState } from 'react';
import api from '../../api/api';
import { Breadcrumbs, Grid, Link, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Link as RouteLink, useParams } from "react-router-dom";
import Debug from './../../components/Debug'
import AssigListResults from './../../components/AssigListResults'
import PersonIcon from '@material-ui/icons/Person';
import AssigListActive from '../../components/AssigListActive';
import CommonList from '../../components/CommonList';
import moment from 'moment';
import TestStatus from '../../components/TestStatus';

  
export default function TestListPage(props) {
    const [test, setTest] = useState([])
    const history = useHistory()
    let { id } = useParams();

    useEffect(() => {
        api.get_org_test_detail(id).then(
            res => setTest(res),
            err => {}
        )
    }, [history, id])

    return (
        <ClientsOfficeView>
            <Breadcrumbs aria-label="breadcrumb">
              <Link component={RouteLink} color="inherit" to="/dashboard" >
                Главная
              </Link>
              <Link component={RouteLink} color="inherit" to="/testlist" >
                Тесты
              </Link>
              <Typography color="textPrimary">{test.description}</Typography>
            </Breadcrumbs>
            
            <Grid container alignItems="flex-end">
                <Grid item xs>
                    <h1 style={{marginBottom:0}}>
                        {test.description}
                    </h1>
                    <Debug>
                        <small style={{color:'#555'}}>id: {test.id}</small>
                    </Debug>
                </Grid>
                <Grid item xs style={{textAlign:'right'}}>
                    <TestStatus test={test} />
                </Grid>
            </Grid>

            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>

                    <h3>Назначенные тесты</h3>            

                    <AssigListActive
                        onSelect={x => history.push('/personlist/'+x.person?.id)} 
                        icon={x => <PersonIcon />}
                        title={x => 'Таб.№ ' + x.testedPersonCustomNumber + ' — ' + x.person?.description}
                        dataLoader={() => api.get_org_test_assignments_active(id)}
                    />
                    
                </Grid>
                <Grid item xs={12} md={6}>
                    <h3>Пройденные тесты и доступные отчеты</h3>
                    
                    <AssigListResults 
                        icon={x => <PersonIcon />}
                        title={x => 'Таб.№ ' + x.testedPersonCustomNumber + ' — ' + x.person?.description}
                        onSelect={x => history.push('/personlist/'+x.person?.id)} 
                        dataLoader={() => api.get_org_test_assignments_results(id)}
                    />

                    <br/>
                    <h3>Не пройденные тесты</h3>

                    <CommonList 
                        onSelect={x => history.push('/personlist/'+x.person?.id)} 
                        dataLoader={() => api.get_org_test_assignments_expired(id)}
                        icon={x => <PersonIcon />}
                        title={x => x.testDescription}
                        desc={x => moment(x.expiresMoscow, 'DD.MM.YYYY HH:mm:ss').format('D MMM YYYY, HH:mm')} 
                    />
                
                </Grid>
            </Grid>
        </ClientsOfficeView>
    )
}

