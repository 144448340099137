
import React, { useEffect, useState } from 'react';
import { Button, DialogTitle, DialogContent, Dialog, ButtonGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import csvtojson from 'csvtojson'
import api from '../api/api';

const charsets = [
    'CP1251',
    'UTF-8',
    'CP1252',
    'CP1250',
    'CP866',
    // 'ASCII',
    'CP437',
    // 'MacCyrillic',
    // 'KOI8-R',
    // 'KOI7-R',
    // 'ISO-8859-1',
    'ISO-8859-5',
    // 'KOI-8R', 
    // 'Latin1'
]

export function SelectCsvFileDialog({ onClose, open, file, persons = [], onChange=()=>{} }) {
    const { enqueueSnackbar } = useSnackbar();
    const [charsetIndex, setCharsetIndex] = useState(0);
    const [data, setData] = React.useState([]);

    const handleClose = () => {
      onClose();
    };

    const CountCol = data[0]?.length
    
    const NotNumberCN = data
                .map((x,i)=> [parseInt(x[0]).toString() === x[0].toString(), i+1] )
                .filter(x=>x[0] === false)
                .map(x=>x[1])

    const NotUniquelyCN = data
                .map((x,i)=>[data.findIndex((d,ii)=>d[0] === x[0] && i > ii), i+1] )
                .filter(x=> x[0] !== -1)
                .map(x=>x[1])

    const ExistCN = data
                .map((x,i)=>[persons.findIndex(p=>p.customNumber === x[0] && p.active === true), i+1] )
                .filter(x=> x[0] !== -1)
                .map(x=>x[1])

    const onCommit = () => {
        api.create_tested_person_multi(data.map(x=> ({
            customNumber: x[0].toString(),
            description: x[1].toString(),
        }))).then(res => {
            enqueueSnackbar('Участники добавлены', { 
                variant: 'success',
              })
            onChange();
        }, err => {
            enqueueSnackbar('Добавить не удалось', { 
                variant: 'error',
              })
            onChange();
        })
        onClose();
    }

    useEffect(() => {
        if (file !== null ) {
            loadCsvData(file, charsets[charsetIndex]).then(res => setData(res))
        }
    }, [charsetIndex, file])

    const loadCsvData = (file, charset = 'windows-1251') => {
        return new Promise( (resolve, reject) => {
            console.log('FileReader', file, charset)
            const reader = new FileReader()
            reader.onload = () => {
                csvtojson({
                    noheader:true,
                    output: "csv",
                    delimiter: "auto",
                })
                .fromString(reader.result)
                .then((csvArray) => { 
                    console.log(csvArray)
                    return resolve(csvArray)
                }, err => reject(err))
            }
            reader.readAsText(file, charset)
        } )
    }


    return (
        <Dialog onClose={handleClose} aria-labelledby="csv-dialog-title" open={open}>
            <DialogTitle id="csv-dialog-title">
                Загрузка CSV-списка участников
            </DialogTitle>
            <DialogContent>
                <p>Количество строк: {data.length}</p>
                <p>Проверка количество столбцов: &nbsp;
                    {CountCol === 2 ? 
                    <span style={{color:'green'}}>Ok</span> : 
                    <span style={{color:'red'}}>Ошибка. Их {CountCol}.</span>}
                </p>
                <p>Проверка Таб.№ на число: &nbsp;
                    {NotNumberCN.length === 0 ? 
                    <span style={{color:'green'}}>Ok</span> : 
                    <span style={{color:'red'}}>
                        <br/>В строках за номером {JSON.stringify(NotNumberCN)} Таб.№ не число.
                    </span>}
                </p>
                <p>Проверка уникальности Таб.№: &nbsp;
                    {NotUniquelyCN.length === 0 ? 
                    <span style={{color:'green'}}>Ok</span> : 
                    <span style={{color:'red'}}>
                        <br/>В строках за номером {JSON.stringify(NotUniquelyCN)} Таб.№ повторяется.
                        </span>}
                </p>
                <p>Проверка существующих Таб.№: &nbsp;
                    {ExistCN.length === 0 ? 
                    <span style={{color:'green'}}>Ok</span> : 
                    <span style={{color:'red'}}>
                        <br/>В строках за номером {JSON.stringify(ExistCN)} Таб.№ уже занят.
                    </span>}
                </p>
                
                <p><br/>Кодировка</p>
                <ButtonGroup size="small">                
                    { charsets.map((x,i) => (
                        <Button key={i} color="primary" variant={charsetIndex === i ? 'contained' : 'outlined'} onClick={() => setCharsetIndex(i)}>
                            {x}
                        </Button>
                    ))}
                </ButtonGroup>

                <br/>

                <code>
                    {data.slice(0,3).map((x,i)=><div key={i}><br/>{x[0]} — {x[1]}</div>)}
                    {data.length > 3 ? <><br/>...</> : ''}
                </code>

                <br/>
                <br/>

                <Button variant="contained" disabled={(CountCol === 2 && NotNumberCN.length === 0 && NotUniquelyCN.length === 0 && ExistCN.length === 0) ? false : true} color="primary" onClick={onCommit}>
                    Загрузить ({data.length})
                </Button>

                <br/>
                <br/>

            </DialogContent> 
        </Dialog>
    );
}
  
SelectCsvFileDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};



  
export default function LoadCsvBtn({persons, children, disabled = false, onChange=()=>{}}) {
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = React.useState(null);
    
  
    const handleClose = () => {
      setOpen(false);
    };
  

    const loadCsv = (input) => {
        if (!input.files || input.files.length === 0) return

        let file = input.files[0]
        setFile(file)
        setOpen(true)
    }

    const openDialog = (input_id) => {
        document.querySelector(input_id).value = null
        document.querySelector(input_id).click()
    }

    return (
      <>
        <Button variant="contained" color="primary" onClick={() => openDialog('#csvInput')}>
          Загрузить CSV
        </Button>

        <input id="csvInput" type="file" style={{display:'none'}} onChange={(e) => loadCsv(e.target)} />

        <SelectCsvFileDialog open={open} file={file} onClose={handleClose} persons={persons} title={children} onChange={onChange} />
      </>
    );
}
  