
import TestTakersOfficeView from './../../viewports/TestTakersOfficeView'
import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useHistory } from 'react-router';
import api from '../../api/api';
import moment from 'moment'
import CommonList from '../../components/CommonList';

const defLogo = '/logo.png'

export default function DeskPage(props) {
    const history = useHistory();
    const [logoUrl, setLogoUrl] = useState(defLogo)
    
    useEffect(() => {
        api.get_person_logo().then(
            res => setLogoUrl(res.logoUrl), 
            err => setLogoUrl(defLogo),
        )
    }, [history])

    return (
        <TestTakersOfficeView>

            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={4} style={{textAlign:'center'}}>
                    <br/>
                    <img src={logoUrl} alt="logo" width="250" /> 
                </Grid>
                <Grid item xs={12} md={8}>

                    <h1>Тесты</h1>

                    <p>Выберете тест для прохождения</p>
                    
                    <CommonList
                        dataLoader={()=>api.get_person_assignments()}
                        icon={x => <AssignmentIcon/>}
                        title={x => x.testDescription}
                        desc={x => <>Дата окончания теста {moment(x.expiresMoscow, 'DD.MM.YYYY HH:mm:ss').format('D MMM YYYY, HH:mm')}</>}
                        actions={x => <Button onClick={() => history.push("/test/" + x.id )} variant="contained" color="primary" >Начать</Button>}
                    />

                </Grid>    
            </Grid>
        </TestTakersOfficeView>
    )
}
