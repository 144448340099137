
import React, { } from 'react';
import { useHistory } from 'react-router';
import { Link as RouteLink } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ClientsOfficeView from './../../viewports/ClientsOfficeView'
import CommonList from '../../components/CommonList';
import TestStatus from '../../components/TestStatus';
import Debug from '../../components/Debug';
import api from '../../api/api';


export default function TestListPage() {
    const history = useHistory()

    return (
        <ClientsOfficeView>
            <Breadcrumbs aria-label="breadcrumb">
                <Link component={RouteLink} color="inherit" to="/dashboard">
                    Главная
                </Link>
                <Typography color="textPrimary">Тесты</Typography>
            </Breadcrumbs>

            <h1>Список доступных тестов</h1>

            <CommonList 
                dataLoader={() => api.get_org_tests()} 
                onSelect={x => history.push("/testlist/"+x.id)} 
                icon={x => <AssignmentIcon />} 
                title={x => x.description} 
                desc={x => Debug.is ? x.id : ''}
                actions={x => <TestStatus test={x} />}
            />
        </ClientsOfficeView>
    )
}

