import { Check } from "@material-ui/icons"

export default function TestStatus({test}) {
    if (test.active) {
        return (
            <p style={{display:'flex', alignItems: 'center', justifyContent:'flex-end', marginRight:'10px', color:'green'}}>
                <Check /> &nbsp; Активен
            </p> 
        )
    } else {
        return (
            <p style={{display:'flex', alignItems: 'center', justifyContent:'flex-end', marginRight:'10px'}}>
                Отключен
            </p> 
        )
    }
}