
import BaseView from './BaseView'
import { AppBar, Button, Toolbar } from "@material-ui/core";
import { NavLink } from "react-router-dom";


function ClientsOfficeHeader() {
    return (
        <AppBar position="static" color="secondary">
            <Toolbar>
                <Button component={NavLink} activeClassName="active" color="inherit" to="/dashboard"><img src="logo_psyscanner.svg" width="200" alt="PSY Scanner" /></Button>
                <Button component={NavLink} activeClassName="active" color="inherit" to="/dashboard">Главная</Button>
                <Button component={NavLink} activeClassName="active" color="inherit" to="/personlist">Участники</Button>
                <Button component={NavLink} activeClassName="active" color="inherit" to="/testlist">Тесты</Button>
                <div style={{flexGrow: 1}} />
                <Button component={NavLink} activeClassName="active" color="inherit" to="/config">Настройки</Button>
                <Button component={NavLink} activeClassName="active" color="inherit" to="/logout">Выход</Button>
            </Toolbar>
        </AppBar>
    )
}

export default function ClientsOfficeView({children, header, footer}) {
    return (
        <BaseView header={header || <ClientsOfficeHeader/>} children={children} footer={footer} />
    )
}