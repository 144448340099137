
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import api from '../../api/api'
import ClientsOfficeView from './../../viewports/ClientsOfficeView'

function StatItem({title, val}) {
    return (
        <Card>
            <CardContent>
                <Typography color="textSecondary">
                    {title}
                </Typography>
                <Typography variant="h5" component="h2">
                    {val}
                </Typography>
            </CardContent>
        </Card>
    )
}

const defLogo = '/logo.png'

function DashboardPage(props) {
    const history = useHistory()
    const [logoUrl, setLogoUrl] = useState(defLogo)
    const [stat, setStat] = useState({})
    
    useEffect(() => {
        api.get_org_logo().then(
            res => setLogoUrl(res.logoUrl), 
            err => setLogoUrl(defLogo), 
        )
        api.get_org_test_reports_info().then(
            res => setStat(res),
            err => {}
        )
    }, [history])

    return (
        <ClientsOfficeView>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={5} style={{textAlign:'center'}}>
                    <img src={logoUrl} alt="logo" width="250" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <h4>Статистика по тестам</h4>
                    <Grid container spacing={3} alignItems="center">
                        { stat.usedResultsCount ? <Grid item xs={6}><StatItem title="Использовано" val={stat.usedResultsCount} /></Grid> : '' }
                        { stat.remainResultsCount ? <Grid item xs={6}><StatItem title="Осталось" val={stat.remainResultsCount} /></Grid> : '' }
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                        { stat.testsAssigned ? <Grid item xs={6}><StatItem title="Назначено" val={stat.testsAssigned} /></Grid> : '' }
                        { stat.reportsAssigned ? <Grid item xs={6}><StatItem title="Отчетов" val={stat.reportsAssigned} /></Grid> : '' }
                    </Grid>
                    <br/>
                    {/* <h4>Форматы отчетов</h4>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs><StatItem title="HTML" val={stat.useHtml ? 
                            <span style={{display:'flex', alignItems: 'center', justifyContent:'space-between', color:'green'}}>
                                <small><small>Доступен</small></small>
                                <CheckIcon />  
                            </span> 
                            : 
                            <span style={{display:'flex', alignItems: 'center', justifyContent:'space-between', color:'grey'}}>
                                <small><small>Отключен</small></small>
                            </span>
                        } /></Grid>
                        <Grid item xs><StatItem title="CSV" val={stat.useCsv ? 
                            <span style={{display:'flex', alignItems: 'center', justifyContent:'space-between', color:'green'}}>
                                <small><small>Доступен</small></small>
                                <CheckIcon />  
                            </span> 
                            : 
                            <span style={{display:'flex', alignItems: 'center', justifyContent:'space-between', color:'grey'}}>
                                <small><small>Отключен</small></small>
                            </span>
                        } /></Grid>
                    </Grid> */}
                </Grid>
            </Grid>


            { window.CONFIG.debug_mode ? <>
                <h3>reports-info</h3>
                <pre>{JSON.stringify(stat, true, "    ")}</pre>
                <br/>
            </> : ''}

            <style>{`
                .textfield-title { margin: 0 20px !important; width: 100%;  }
                .textfield-title input { font-size: 30px; font-weight: bold;  }
                .textfield-title .MuiFormLabel-root { font-size: 20px;  }
            `}</style>
        </ClientsOfficeView>
    )
}

export default DashboardPage