
import React from 'react';
import { Button } from '@material-ui/core';
import Footer from './Footer';


function CallPrint(strid) {
    var prtContent = document.getElementById(strid);
    var WinPrint = window.open('','','left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0');
    WinPrint.document.write('<div id="print" class="contentpane">');
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.write('</div>');
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
}
  
export default function PrintPersonBtn({ids = [], persons, children}) {
    return (
      <>
        <Button variant="contained" color="primary" onClick={() => {if ( ids.length > 0) CallPrint('print-content')}}>
          {children}
        </Button>

        <div id="print-content" style={{display:'none'}}>
            <h1>Список тестируемых участников</h1>
            { ids
                .map(x=>persons.find(p=>p.id === x))
                .map(p=>(
                    <div key={p.id} style={{pageBreakInside: 'avoid', float:'left', width:'50%'}}>
                        <br/>
                        
                        <h3>{p.description} — Таб.№ {p.customNumber}</h3>
                        <p>login: {p.login} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; password: {p.password}</p>
                    </div>
                ))
            }
            <br clear="both" />
            <Footer />
        </div>
  
        &nbsp;
        &nbsp;
      </>
    );
}
  