import { useEffect, useState } from "react";


var requestAnimationFrame = window.requestAnimationFrame || 
                            window.mozRequestAnimationFrame ||
                            window.webkitRequestAnimationFrame;

var t = [];
var fps = 0;
var enable_fps = false
var fail_fps = 0

function animate(now) {
    t.unshift(now);
    if (t.length > 10) {
        var t0 = t.pop();
        fps = Math.floor(1000 * 10 / (now - t0));
    }
    if (enable_fps) {
        requestAnimationFrame(animate);
    }
};

export default function FPS({onFailCount = () => {}}) {
    const [valfps, setValfps] = useState(0)
    const [failCount, setFailCount] = useState(0)

    useEffect(()=> {
        enable_fps = true;
        requestAnimationFrame(animate);

        let iid = setInterval(()=> {
            setValfps(fps)
            if (fps < 58) {
                fail_fps += 1
                setFailCount( fail_fps )
                onFailCount( fail_fps )
            }
        }, 50)

        return () => {
            enable_fps = false
            fail_fps = 0
            clearInterval(iid)
        }
    }, [])

    return ( 
        <>
            <span style={{color: fps >= 58 ? 'white' : 'red'}}>{valfps} fps </span>
            &nbsp;/&nbsp;
            <span style={{color: failCount < 10 ? 'white' : 'red'}}>{failCount} pass</span>
        </>
    )
}