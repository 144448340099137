
import BaseView from './BaseView'
import { AppBar, Button, Toolbar } from "@material-ui/core";
import { NavLink } from "react-router-dom";


function TestTakersOfficeHeader() {
    return (
        <AppBar position="static" color="primary">
            <Toolbar>
                <Button component={NavLink} activeClassName="active" color="inherit" to="/desk"><img src="logo_psyscanner.svg" width="200" alt="PSY Scanner" /></Button>
                <div style={{flexGrow: 1}} />
                <Button component={NavLink} activeClassName="active" color="inherit" to={"/logout"}>Выход</Button>
            </Toolbar>
        </AppBar>
    )
}

export default function TestTakersOfficeView({children, header, footer}) {
    return (
        <BaseView header={header || <TestTakersOfficeHeader />} children={children} footer={footer} />
    )
}