
import React from 'react';

import {
    Button,
} from '@material-ui/core';

import moment from 'moment'
import CommonList from './CommonList';
import actions from '../api/actions';

export default function AssigListResults(props) {
    return (
        <CommonList 
            desc={x=>moment(x.passedMoscow, 'DD.MM.YYYY HH:mm:ss').format('D MMM YYYY, HH:mm')}
            actions={x => x.finished ? 
                <>
                    {(x.downloadableFiles || []).map((f,i)=>(
                        <Button onClick={e => { actions.download_report(x, f); e.stopPropagation(); }} 
                            variant="contained" 
                            color="secondary"
                            style={{marginLeft:'4px'}}
                            >{f}</Button>
                    ))}
                </>
                : 'Отчет готовится'    
            }
            {...props}
        />
    )
}
