
import { Container } from "@material-ui/core";
import Footer from './../components/Footer'
import './BaseView.css'


export default function BaseView({children, header, footer = <Footer/>}) {
    return (
        <div className="base-view" style={{background:'#eee', minHeight:'100%'}}>
            <header>
                {header}
            </header>
            <Container className="base-content">
                {children}
            </Container>
            <footer>
                {footer}
            </footer>
        </div>
    )
}